import { useNavigate } from '@remix-run/react';
import { useEffect } from 'react';
import { useEffectOnce } from 'react-use';
import { $path } from 'remix-routes';

import { type DtoProgram } from '@lp-lib/api-service-client/public';

import { useOnboardingAnalytics } from '../analytics/onboarding';
import { buildSearchParamsWithRedirectToAsString } from '../components/Access/hooks';
import { useSlackInstalledCallbackModal } from '../components/Channel';
import { GlobalLoading } from '../components/GlobalLoading';
import { Loading } from '../components/Loading';
import { OnboardingPageLayout } from '../components/Onboarding/OnboardingLayout';
import { OrgRequired } from '../components/Organization';
import {
  ProgramsActivation,
  useProgramActivationData,
} from '../components/Program/ProgramsActivation';
import { getEnv } from '../config/getEnv';
import { marketing } from '../config/marketing';
import { useQueryParam } from '../hooks/useQueryParam';
import { type Organization } from '../types';

function Completed(props: { activatedPrograms: DtoProgram[] }) {
  const { activatedPrograms } = props;

  const analytics = useOnboardingAnalytics();
  const navigate = useNavigate();

  useEffectOnce(() => {
    analytics.trackOnboardingProgramsInstalled({
      count: activatedPrograms.length,
      programIds: activatedPrograms.map((p) => p.id),
      programTypes: activatedPrograms.map((p) => p.type),
    });

    navigate(
      {
        pathname: '/onboarding/invite-few-members',
        search: buildSearchParamsWithRedirectToAsString(
          $path('/onboarding/completed')
        ),
      },
      {
        replace: true,
      }
    );
  });

  return <Loading />;
}

function Container(props: { organization: Organization }) {
  const programId = useQueryParam('program-id');
  const analytics = useOnboardingAnalytics();
  const navigate = useNavigate();
  const { data, isLoading, error } = useProgramActivationData(
    props.organization.id,
    {
      additionalSelectedProgramIds: programId ? [programId] : undefined,
    }
  );

  if (isLoading) return <GlobalLoading />;
  if (error || !data) throw error;
  const { uninstalledPrograms, initSelectedPrograms } = data;

  const handleSkip = () => {
    analytics.trackOnboardingProgramsSkipped();

    navigate({
      pathname: '/onboarding/invite-few-members',
      search: buildSearchParamsWithRedirectToAsString(
        $path('/onboarding/completed')
      ),
    });
  };

  return (
    <OnboardingPageLayout progress={80}>
      <ProgramsActivation
        orgId={props.organization.id}
        programs={uninstalledPrograms}
        initSelectedPrograms={initSelectedPrograms}
        onSkip={handleSkip}
        renderCompleted={({ activatedPrograms }) => (
          <Completed activatedPrograms={activatedPrograms} />
        )}
      />
    </OnboardingPageLayout>
  );
}

function useEmitGoogleAdsEvent(
  enabled = marketing(getEnv()).registrationMarketingTrackingEnabled
) {
  useEffect(() => {
    if (!enabled) return;
    gtag('event', 'conversion', {
      send_to: 'AW-10811798990/3W4UCLvuxIkZEM7ru6Mo',
    });
  }, [enabled]);
}

export function Component() {
  useSlackInstalledCallbackModal();
  useEmitGoogleAdsEvent();

  return (
    <OrgRequired>
      {(organization) => <Container organization={organization} />}
    </OrgRequired>
  );
}
